import {
  BussinessTypeRangeLives1Tokens,
  BussinessTypeRangeLives2Tokens,
  IndividualWithShortageTokens,
  IndividualWithShortageNorthEastTokens,
} from '../../../utils/Enums/Tokens';
export interface Plan {
  type?: string;
  name: string;
  iconSource: string;
  category: Category[];
  covers: string[];
  coversPlus?: string[];
  shortage?: string;
  typeRangeLives?: number;
}

interface Category {
  type: string;
  priceNE: string;
  priceAll: string;
  price: string;
  token: string;
}

export const personalPlans: Plan[] = [
  {
    name: 'OdontoClínico',
    iconSource: '/assets/images/icons/planos/clinico.svg',
    category: [
      {
        type: 'Com Carencia',
        priceNE: `${process.env.REACT_APP_OG_GET_PLAN_PRICE_INDIVIDUAL}PE/${IndividualWithShortageNorthEastTokens.CLINICO}`,
        priceAll: `${process.env.REACT_APP_OG_GET_PLAN_PRICE_INDIVIDUAL}DF/${IndividualWithShortageTokens.CLINICO}`,
        price: '',
        token: IndividualWithShortageTokens.CLINICO, // 709
      },
    ],
    covers: [
      'Limpeza',
      'Restauração',
      'Tratamento de Canal',
      'Extrações',
      'Raio X',
      'Emergência 24h',
      'Prótese',
      '+ 200 procedimentos',
    ],
  },
  {
    name: 'OdontoDoc',
    iconSource: '/assets/images/icons/planos/doc.svg',
    category: [
      {
        type: 'Com Carencia',
        priceNE: `${process.env.REACT_APP_OG_GET_PLAN_PRICE_INDIVIDUAL}DF/${IndividualWithShortageNorthEastTokens.DOC}`,
        priceAll: `${process.env.REACT_APP_OG_GET_PLAN_PRICE_INDIVIDUAL}DF/${IndividualWithShortageTokens.DOC}`,
        price: '',
        token: IndividualWithShortageTokens.DOC, // 791
      },
    ],
    covers: ['+ Documentação para aparelho', '+ Aparelho metálico'],
    coversPlus: ['Todas coberturas do plano <b>Clínico</b>'],
  },
  {
    name: 'OdontoOrto',
    iconSource: '/assets/images/icons/planos/orto.svg',
    category: [
      {
        type: 'Com Carencia',
        priceNE: `${process.env.REACT_APP_OG_GET_PLAN_PRICE_INDIVIDUAL}PE/${IndividualWithShortageNorthEastTokens.ORTO}`,
        priceAll: `${process.env.REACT_APP_OG_GET_PLAN_PRICE_INDIVIDUAL}DF/${IndividualWithShortageTokens.ORTO}`,
        price: '',
        token: IndividualWithShortageTokens.ORTO, // 713
      },
    ],
    covers: ['+ Manutenção Aparelho', '+ Clareamento caseiro'],
    coversPlus: ['Todas coberturas do plano <b>Doc</b>'],
  },
  {
    name: 'OdontoPrótese',
    iconSource: '/assets/images/icons/planos/protese.svg',
    category: [
      {
        type: 'Com Carencia',
        priceNE: `${process.env.REACT_APP_OG_GET_PLAN_PRICE_INDIVIDUAL}DF/${IndividualWithShortageNorthEastTokens.PROTESE}`,
        priceAll: `${process.env.REACT_APP_OG_GET_PLAN_PRICE_INDIVIDUAL}DF/${IndividualWithShortageTokens.PROTESE}`,
        price: '',
        token: IndividualWithShortageTokens.PROTESE, //796
      },
    ],
    covers: [
      '+ Clareamento caseiro',
      '+ Prótese total',
      '+ Prótese removível',
      '+ Placa de bruxismo',
    ],
    coversPlus: ['Todas coberturas do plano <b>Clínico</b>'],
  },
  {
    name: 'OdontoKids',
    iconSource: '/assets/images/icons/planos/kids.svg',
    category: [
      {
        type: 'Com Carencia',
        priceNE: `${process.env.REACT_APP_OG_GET_PLAN_PRICE_INDIVIDUAL}DF/${IndividualWithShortageNorthEastTokens.KIDS}`,
        priceAll: `${process.env.REACT_APP_OG_GET_PLAN_PRICE_INDIVIDUAL}DF/${IndividualWithShortageTokens.KIDS}`,
        price: '',
        token: IndividualWithShortageTokens.KIDS, //937
      },
    ],
    covers: [
      'Limpeza',
      'Restaurações',
      'Tratamento de Canal',
      'Extrações',
      'Bloco metálico',
      'Coroa Metálica',
      'Coroa em Resina Dentes Anteriores',
      '+ 200 procedimentos',
    ],
    coversPlus: [],
  },
];

export const businessPlans: Plan[] = [
  {
    name: 'OdontoClínico',
    iconSource: '/assets/images/icons/planos/clinico.svg',
    category: [
      {
        type: '2 a 29 Vidas',
        priceNE: `${process.env.REACT_APP_OG_GET_PLAN_PRICE_COMPANY}${BussinessTypeRangeLives1Tokens.CLINICO}`,
        priceAll: `${process.env.REACT_APP_OG_GET_PLAN_PRICE_COMPANY}${BussinessTypeRangeLives1Tokens.CLINICO}`,
        price: '',
        token: BussinessTypeRangeLives1Tokens.CLINICO,
      },
    ],
    covers: [
      'Limpeza',
      'Restaurações',
      'Tratamento de Canal',
      'Extrações',
      'Bloco metálico',
      'Coroa Metálica',
      'Coroa em resina dentes anteriores',
      '+ 200 procedimentos',
    ],
    shortage: 'Prótese: 180 dias',
    typeRangeLives: 1,
  },
  {
    name: 'OdontoDoc',
    iconSource: '/assets/images/icons/planos/doc.svg',
    category: [
      {
        type: '2 a 29 Vidas',
        priceNE: `${process.env.REACT_APP_OG_GET_PLAN_PRICE_COMPANY}${BussinessTypeRangeLives1Tokens.DOC}`,
        priceAll: `${process.env.REACT_APP_OG_GET_PLAN_PRICE_COMPANY}${BussinessTypeRangeLives1Tokens.DOC}`,
        price: '',
        token: BussinessTypeRangeLives1Tokens.DOC,
      },
    ],
    covers: ['+ Documentação para aparelho', '+ Aparelho metálico'],
    coversPlus: ['Todas as coberturas do plano Clínico'],
    shortage: 'Prótese: 180 dias',
    typeRangeLives: 1,
  },
  {
    name: 'OdontoOrto',
    iconSource: '/assets/images/icons/planos/orto.svg',
    category: [
      {
        type: '2 a 29 Vidas',
        priceNE: `${process.env.REACT_APP_OG_GET_PLAN_PRICE_COMPANY}${BussinessTypeRangeLives1Tokens.ORTO}`,
        priceAll: `${process.env.REACT_APP_OG_GET_PLAN_PRICE_COMPANY}${BussinessTypeRangeLives1Tokens.ORTO}`,
        price: '',
        token: BussinessTypeRangeLives1Tokens.ORTO,
      },
    ],
    covers: [
      '+ Aparelho metálico',
      '+ Manutenções mensais',
      '+ Clareamento caseiro',
    ],
    coversPlus: ['+ Manutenção Aparelho', '+ Clareamento caseiro'],
    shortage: 'Prótese: 180 dias',
    typeRangeLives: 1,
  },
  {
    name: 'OdontoClínico',
    iconSource: '/assets/images/icons/planos/clinico.svg',
    category: [
      {
        type: '30 a 199 Vidas',
        priceNE: `${process.env.REACT_APP_OG_GET_PLAN_PRICE_COMPANY}${BussinessTypeRangeLives2Tokens.CLINICO}`,
        priceAll: `${process.env.REACT_APP_OG_GET_PLAN_PRICE_COMPANY}${BussinessTypeRangeLives2Tokens.CLINICO}`,
        price: '',
        token: BussinessTypeRangeLives2Tokens.CLINICO,
      },
    ],
    covers: [
      'Limpeza',
      'Restauração',
      'Tratamento de Canal',
      'Extrações',
      'Raio X',
      'Emergência 24h',
      'Prótese',
      '+ 200 procedimentos',
    ],
    shortage: 'Prótese: 180 dias',
    typeRangeLives: 2,
  },
  {
    name: 'OdontoDoc',
    iconSource: '/assets/images/icons/planos/doc.svg',
    category: [
      {
        type: '30 a 199 Vidas',
        priceNE: `${process.env.REACT_APP_OG_GET_PLAN_PRICE_COMPANY}${BussinessTypeRangeLives2Tokens.DOC}`,
        priceAll: `${process.env.REACT_APP_OG_GET_PLAN_PRICE_COMPANY}${BussinessTypeRangeLives2Tokens.DOC}`,
        price: '',
        token: BussinessTypeRangeLives2Tokens.DOC,
      },
    ],
    covers: [
      'Limpeza',
      'Restauração',
      'Tratamento de Canal',
      'Extrações',
      'Raio X',
      'Emergência 24h',
      'Prótese',
      '+ 200 procedimentos',
    ],
    coversPlus: ['+ Documentação para aparelho', '+ Aparelho metálico'],
    shortage: 'Prótese: 180 dias',
    typeRangeLives: 2,
  },
  {
    name: 'OdontoOrto',
    iconSource: '/assets/images/icons/planos/orto.svg',
    category: [
      {
        type: '30 a 199 Vidas',
        priceNE: `${process.env.REACT_APP_OG_GET_PLAN_PRICE_COMPANY}${BussinessTypeRangeLives2Tokens.ORTO}`,
        priceAll: `${process.env.REACT_APP_OG_GET_PLAN_PRICE_COMPANY}${BussinessTypeRangeLives2Tokens.ORTO}`,
        price: '',
        token: BussinessTypeRangeLives2Tokens.ORTO,
      },
    ],
    covers: [
      'Limpeza',
      'Restauração',
      'Tratamento de Canal',
      'Extrações',
      'Raio X',
      'Emergência 24h',
      'Prótese',
      '+ 200 procedimentos',
    ],
    coversPlus: [
      '+ Aparelho metálico',
      '+ Manutenções mensais',
      '+ Clareamento caseiro',
    ],
    shortage: 'Prótese: 180 dias',
    typeRangeLives: 2,
  },
];
